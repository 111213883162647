.root {
  min-width: 1440px;
  margin-top: 200px;
  padding: 0 12.5%;

  .logo-wrapper {
    position: absolute;
    background-color: white;
    border-radius: 20px;
    padding: 8px;
    width: 80px;
    height: 80px;
    top: -32px;
    left: calc(50% - 40px);
    .logo-image {
      width: 64px;
    }
  }

  .content {
    color: #1e293b;
    background-color: #f1f4f9;
    border-radius: 20px;
    padding: clamp(24px, 4%, 80px) clamp(24px, 5%, 100px);

    .title {
      text-align: center;
      margin-bottom: 100px;
    }

    .description {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .stepline {
    position: absolute;
    padding-top: 160px;

    .step {
      font-weight: 800;
      width: 32px;
      height: 32px;
      margin-left: 4px;
      padding: 6px 12px;
      border-radius: 16px;
      margin-bottom: 400px;
      background: #B2B7C0;
      color: #F0F2F5;
      &.active {
        margin-left: 0px;
        background: #f1f4f9;
        width: 40px;
        height: 40px;
        color: #2f54eb;
        border: 6px solid #2f54eb;
        border-radius: 20px;
        padding: 4px 10px;
      }
    }
    .ghost {
      position: absolute;
      top: 200px;
      left: 19px;
      width: 2px;
      height: 840px;
      background: #1e293b;
      opacity: 0.3;
    }
    .line {
      position: absolute;
      width: 2px;
      top: 200px;
      left: 19px;
      background: #2f54eb;
    }
  }
}
