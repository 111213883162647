@import '@/styles/colors.module.scss';

.sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;

  .header {
    height: 64px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .divider {
    border-color: $blue-gray-8;
    background-color: $blue-gray-8;
    margin: 0;
  }

  .company {
    margin-top: 24px;
    margin-bottom: 12px;
    padding: 0 24px;
    line-height: 1em;
  }

  .content {
    height: 100%;
    align-content: space-between;
  }

  .menu {
    background: transparent;
    padding: 12px 16px;
    .divider {
      border-color: $blue-gray-8;
      background-color: $blue-gray-8;
      margin: 0 -16px;
    }
  }

  .meta {
    margin: 16px 16px 0;
    padding: 0 16px;
    .admin {
      margin-bottom: 2px;
    }
    [class='ant-list-item-meta-title'] {
      color: $gray-1;
    }
    [class='ant-list-item-meta-description'] {
      color: $blue-gray-4;
    }
  }
}
