.header {
  padding: 24px;
}

.content {
  background-color: #fff;
}

.filters,
.tabs {
  padding: 0 24px;
}

.search {
  width: 300px;
}

.result {
  padding: 13px 24px;
}

.table {
  [class~='ant-table-pagination'] {
    margin: 16px;
  }
  [class~='ant-table-row'] {
    cursor: pointer;
  }
}
