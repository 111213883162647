// colors
$blue-gray: #f1f4f9;
$blue-gray-1: #f9fafc;
$blue-gray-2: #e1e8f0;
$blue-gray-4: #94a3b8;
$blue-gray-5: #64748b;
$blue-gray-05: #94a3b8;
$blue-gray-8: #344056;
$cyan-1: #e6fffb;
$gold-1: #fffbe6;
$gold-3: #ffe58f;
$gold-6: #faad14;
$gray-1: #ffffff;
$gray-2: #fafafa;
$gray-5: #d9d9d9;
$gray-9: #262626;
$green-1: #f6ffed;
$green-3: #b7eb8f;
$green-6: #52c41a;
$geek-blue-1: #f0f5ff;
$geek-blue-3: #ADC6FF;
$geek-blue-4: #85a5ff;
$geek-blue-6: #2f54eb;
$main-1: #fff0e8;
$main-3: #ffd2bb;
$main-6: #ff681d;
$magenta-1: #fff0f6;
$magenta-3: #ffadd2;
$magenta-6: #eb2f96;
$orange: #ff681d;
$orange-1: #fff7e6;
$orange-3: #ffd591;
$purple-1: #f9f0ff;
$purple-3: #d3adf7;
$purple-6: #722ed1;
$red-1: #fff1f0;
$red-3: #ffa39e;
$red-6: #f5222d;
$volcano-6: #fa541c;
$yellow-6: #fadb14;

:export {
  blueGray: $blue-gray;
  blueGray1: $blue-gray-1;
  blueGray2: $blue-gray-2;
  blueGray4: $blue-gray-4;
  blueGray5: $blue-gray-5;
  blueGray05: $blue-gray-05;
  blueGray8: $blue-gray-8;
  cyan1: $cyan-1;
  geekBlue1: $geek-blue-1;
  geekBlue3: $geek-blue-3;
  geekBlue4: $geek-blue-4;
  geekBlue6: $geek-blue-6;
  gold1: $gold-1;
  gold3: $gold-3;
  gold6: $gold-6;
  gray1: $gray-1;
  gray2: $gray-2;
  gray5: $gray-5;
  gray9: $gray-9;
  green1: $green-1;
  green3: $green-3;
  green6: $green-6;
  main1: $main-1;
  main3: $main-3;
  main6: $main-6;
  magenta1: $magenta-1;
  magenta3: $magenta-3;
  magenta6: $magenta-6;
  orange: $orange;
  orange1: $orange-1;
  orange3: $orange-3;
  purple1: $purple-1;
  purple3: $purple-3;
  purple6: $purple-6;
  red1: $red-1;
  red3: $red-3;
  red6: $red-6;
  volcano6: $volcano-6;
  yellow6: $yellow-6;
}
