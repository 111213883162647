@import '@/styles/colors.module.scss';

.divider {
  margin: 0;
}

.description {
  color: rgba($color: #000000, $alpha: 0.45);
  font-size: 14px;
}

.detailsList {
  border-radius: 8px;
  padding: 24px;
  background: $blue-gray-1;
}
