
@import './colors.module.scss';

.ant-alert {
  border-radius: 8px;
  margin-bottom: 16px;
}

.ant-alert-info {
  background-color: $orange-1;
  border-color: $orange-3;
  .ant-alert-icon {
    color: $volcano-6;
  }
}

.ant-alert-with-description {
  .ant-alert-description {
    font-size: 16px;
  }
  .ant-alert-message {
    font-size: 20px;
    line-height: 24px;
  }
}