.header {
  background-color: #1e293b;
  height: 134px;
  width: 100%;
  margin-bottom: 80px;
}

.logo {
  position: absolute;
  top: 40px;
}

.layout {
  padding-top: 0;
  white-space: break-spaces;
}
