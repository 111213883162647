@import './colors.module.scss';
@import './ant-alert.scss';
@import './ant-button.scss';
@import './ant-divider.scss';
@import './ant-icon.scss';
@import './ant-input.scss';
@import './ant-input-group.scss';
@import './ant-select.scss';
@import './ant-tooltip.scss';
@import './ant-table.scss';
@import './ant-tag.scss';

.ant-badge-count {
  height: 22px;
  line-height: 22px;
}

.ant-divider-horizontal {
  &.ant-divider-with-text-left {
    &::before {
      content: none;
    }
    &::after {
      width: 100%;
    }
    .ant-divider-inner-text {
      padding-left: 0;
      font-weight: 600;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2f54eb;
}
.ant-checkbox-inner {
  border-radius: 4px;
}

.ant-form {
  font-size: 16px;
}


.ant-input-password {
  .ant-input-suffix {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.ant-input-affix-wrapper {
  padding: 0;
  border-width: 1px;
  border-color: transparent;
  border-radius: 8px;

  &.ant-input-affix-wrapper-focused {
    border-width: 1px;
    border-style: solid;
    border-color: #adc6ff;
    box-shadow: 0px 0px 4px 0px #1890ff80;
  }

  > input.ant-input {
    padding: 8px 12px;
  }
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  background-color: $blue-gray;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 1px solid $blue-gray-2;
}

.ant-form-item {
  margin-bottom: 16px;
  & input[type='checkbox'] {
    width: 16px;
    height: 16px;
  }
}

.ant-form-item-has-error {
  .ant-input-affix-wrapper {
    border-radius: 8px;
    &:hover {
      .ant-input-prefix {
        background: #fff;
      }
    }
  }

  &.ant-input-affix-wrapper,
  &.ant-input {
    border-color: transparent;
    background: $red-1;
  }
}

.ant-form-item-has-error .ant-input-prefix,
.ant-form-item-has-error .ant-input {
  background: $red-1;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: #ff4d4f;
}

.ant-form-item-label > label {
  height: 40px;
}

.anticon {
  color: $blue-gray-5;
}

.ant-layout {
  background-color: $blue-gray;
}

.ant-layout-sider {
  color: #fff;
  line-height: 120px;
  background: #1e293b;
}

.ant-menu-vertical {
  &.ant-menu-dark .ant-menu-item {
    border-radius: 8px;
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: $geek-blue-6;
}

.ant-modal-content {
  border-radius: 20px;
}

.ant-picker {
  background-color: $blue-gray;
  border-radius: 8px;
  border-color: transparent;
}

.ant-select-clear,
.ant-picker-clear {
  background-color: $blue-gray;
}

.ant-steps-item-icon {
  font-family: 'Raleway';
  font-weight: 600;
  line-height: 28px;
}

.ant-steps-item-process {
  .ant-steps-item-icon {
    border-color: #2f54eb;
  }
  > .ant-steps-item-container > .ant-steps-item-icon {
    background: #2f54eb;
  }
}

.ant-steps-item-wait {
  .ant-steps-item-icon {
    border-color: #cbd5e1;
    > .ant-steps-icon {
      color: #ffffff;
    }
  }
  > .ant-steps-item-container > .ant-steps-item-icon {
    background: #cbd5e1;
  }
}

.ant-table-thead > tr > th {
  background: $blue-gray-1;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: $geek-blue-1;
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

.ant-typography {
  &[align='center'] {
    text-align: center;
  }
}

a.ant-typography,
.ant-typography a {
  color: $orange;
}

.ant-radio-group {
  .ant-radio-wrapper,
  .ant-radio-button-wrapper {
    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
    height: 40px;
    font-size: 16px;
    line-height: 38px;
    background-color: $blue-gray;
    padding: 0 16px;
    margin-right: 1px;
  }
  > :first-child {
    border-radius: 8px 0px 0px 8px;
  }
  > :last-child {
    border-radius: 0px 8px 8px 0px;
  }
  .ant-radio-button-wrapper-checked {
    .anticon {
      color: inherit;
    }
  }
}

.ant-radio-group-large {
  .ant-radio-wrapper {
    &.ant-radio-wrapper-checked {
      background: $geek-blue-6;
      color: $gray-1;
      .ant-radio-inner {
        border-color: $geek-blue-6;
      }
    }
    .ant-radio-inner {
      border-color: $gray-2;
      &:after {
        background: $geek-blue-6;
      }
    }
  }
}

.ant-form-item-control-input-content {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $blue-gray !important;
    border-radius: 8px;
    border-color: transparent;
  }
}

.ant-upload.ant-upload-select-picture-card {
  border-radius: 8px;
  background-color: $blue-gray;
}

body {
  color: #000;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.fill-height {
  height: 100%;
}

.bacnground-blue-gray-9 {
  background-color: #1e293b;
}

.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

.display-block {
  display: block;
}

.text-color-main-6 {
  color: $orange;
}
.text-color-gray-5 {
  color: #64748b;
}
.text-color-red-6 {
  color: #f5222d;
}
.text-color-blue-gray-1 {
  color: $blue-gray-1;
}
.text-color-gray-5 {
  color: #64748b;
}

.text-height-24 {
  line-height: 24px;
}
.text-height-32 {
  line-height: 32px;
}
.text-height-38 {
  line-height: 38px;
}

.text-size-14 {
  font-size: 14px;
}
.text-size-16 {
  font-size: 16px;
}
.text-size-24 {
  font-size: 24px;
}
.text-size-30 {
  font-size: 30px;
}

.text-weight-semibold {
  font-weight: 600;
}

.text-wrap-spaces {
  white-space: break-spaces;
}
