@import '@/styles/colors.module.scss';

.root {
  transition: margin 0.2s;
  background-color: $blue-gray;
  margin-left: 256px;
  &.collapsed {
    margin-left: 80px;
  }
  //min-width: 1280px;

  [class='ant-divider'] {
    &.ant-divider-horizontal {
      margin: 16px 0;
    }
  }
}

.body {
  max-width: 1600px;
  margin-top: 24px;
  margin-right: 24px;
  margin-left: 24px;
  padding-bottom: 24px;
}
