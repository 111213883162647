.ant-btn-primary {
  background: $orange;
  border: none;
  span {
    color: #fff;
  }
  &[disabled] {
    background: $orange;
    pointer-events: none;
    opacity: 0.5;
  }
}
.ant-btn-lg,
.ant-btn-large {
  padding: 8px 16px;
}

.ant-btn-round,
.ant-btn-round.ant-btn-lg {
  border-radius: 8px;
}

.ant-btn-background-ghost.ant-btn-primary {
  border: 1px solid $orange;
  span {
    color: $main-6;
  }
}

.ant-btn  {
  &.ant-dropdown-trigger{
    &:hover {
      border-color: $main-6;
      color: $main-6;
      .anticon-down {
        color: $main-6;
      }
    }
  }
}
