.header {
  padding: 16px 24px;
  .title {
    margin: 0;
  }
}
  .divider{
    margin: 0 ;
  }

.footer {
  padding: 24px;
  border-top: 1px solid #e1e8f0;
}

.content {
  padding-bottom:  72px ;
  background-color: #fff;
}

.body {
  padding: 24px;
}
