.root {
  padding: 0 clamp(24px, 12%, 375px);
  color: white;
  margin-top: 100px;
  margin-bottom: 100px;

  .title {
    color: #ff681d;
    font-size: 60px;
  }
  .suffix {
    color: #ff681d;
    font-size: 20px;
  }
  .description {
    color: white;
    font-size: 20px;
  }
}
