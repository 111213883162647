@import '@/styles/colors.module.scss';

.route{
  &::before{
    position: absolute;
    top: -12px;
    content: ' ';
    width: 2px;
    height: 2px;
    border-radius: 2px;
    border: 1px solid $blue-gray-05;
    margin: 6px;
  }
  &::after{
    position: absolute;
    top: -7px;
    content: ' ';
    width: 2px;
    height: 2px;
    border-radius: 2px;
    border: 1px solid $blue-gray-05;
    margin: 6px;
  }
}

.ellipse {
  border: 2px solid $blue-gray-05;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 3px;
}

.location{
  white-space: nowrap;
}