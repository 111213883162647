@import './colors.module.scss';

.ant-input {
  color: #262626 !important;
  background-color: $blue-gray;
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid transparent;

  &:focus {
    border: 1px solid #adc6ff;
    box-shadow: 0px 0px 4px 0px #1890ff80;
  }
}

.ant-input-sm {
  padding: 5px 12px;
}

.ant-input-prefix {
  background-color: $blue-gray;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-right: 0;
  padding-left: 12px;
  transition: background-color 0.3s;

  & + .ant-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ant-input-affix-wrapper {
  .ant-input-suffix {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-left: 0;
    padding-right: 12px;
    transition: background-color 0.3s;
  }
}

.ant-input-affix-wrapper {
  &.ant-input-with-suffix {
    background-color: $blue-gray;
    .ant-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.ant-input-search-button {
  background: none;
  border: none;
  &:hover {
    background: none;
  }
}
