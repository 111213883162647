.description {
  margin-bottom: 32px;
}
.radio-group {
  display: flex;
  & label {
    white-space: nowrap;
    flex-grow: 1;
  }
}
