@import '@/styles/colors.module.scss';

.table {
  th.ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters {
    background-color: $blue-gray-1;
  }

  td.ant-table-column-sort{
    background-color: $blue-gray-1;
  }
}

.blocked {
  background-color: $blue-gray-1;
}