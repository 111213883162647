@import '@/styles/colors.module.scss';

.bid {
  background-color: $blue-gray-1;
  padding: 24px;
  border-radius: 8px;
}

.my-bid {
  border: 1px solid #CBD5E1;
  padding: 16px;
  border-radius: 8px;
}

.stage {
  background-color: $blue-gray-1;
  border-radius: 8px;
  padding: 24px;
  .divider {
    margin-top: 0;
  }
}
