.root {
  background-color: #e5e5e5;
  .card {
    margin-top: -100px;
    background-image: url('../images/feature3-map.png');
    background-position: top right;
    background-repeat: no-repeat;
    width: clamp(900px, 60%, 1200px);
    padding: 112px clamp(35px, 5%, 80px);
    background-color: #1e293b;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.03), 0px 15px 40px 8px rgba(0, 0, 0, 0.04);
    border-radius: 20px;

    .title{
      font-size: 36px;
      font-weight: 700;
      color: white;
    }
  }
}
