@import '@/styles/colors.module.scss';

.label {
  font-size: 14px;
}

.divider {
  margin: 12px 0;
}

.upload {
  overflow: hidden;
  [class~='ant-upload-list-item-name'] {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.name,
.progress {
  color: $blue-gray-5;
}

.name {
  overflow-wrap: anywhere;
}
