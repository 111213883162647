.root {
  background-color: #e5e5e5;
  padding-top: 90px;
  padding-bottom: 90px;

  .title {
    color: black;
    text-align: center;
  }
}
