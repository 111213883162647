.header {
  background-color: #1e293b;
  padding-top: 40px;
  padding-left: 20%;
  .logo {
    width: 237px;
  }
}

.truck {
  position: absolute;
  top: 0;
  right: 0;
  width: clamp(800px, 50vw, 1000px);
  max-width: 1213px;
}
