.root {
  padding: 0 clamp(24px, 22%, 420px);
  color: white;
  margin-top: 144px;
  .title {
    margin-top: 62px;
    text-align: center;
    color: white;
    font-size: 36px;
  }
}
