@import '@/styles/colors.module.scss';

.root {
  margin-bottom: 12px;
  .status {
    color: $blue-gray-05;
    display: inline-flex;
    align-items: center;
    margin-right: 8px;

    &:before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $blue-gray-05;
      margin-right: 5px;
      margin-bottom: 2px;
      transition: background-color 0.4s;
    }

    &.success:before {
      transition: background-color 0.4s;
      background-color: #52c41a;
    }
  }
}
