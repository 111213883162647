.ant-select{
  .ant-select-selector{
    &[shape="round"]{
      border-radius: 8px;
    }
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 8px;
}