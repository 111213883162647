.root {
  padding-left: 20%;
  margin-top: 72px;

  h1 {
    color: white;
    white-space: break-spaces;
    font-size: 48px;
    line-height: 62px;
  }

  span {
    color: white;
    white-space: break-spaces;
  }
}
