@import '@/styles/colors.module.scss';

.group {
  .button {
    border-color: transparent;
    border-radius: 0;
    background-color: $blue-gray;
    box-shadow: none;
    span {
      color: $geek-blue-6;
    }
  }
  :first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  :last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .input {
    width: 80px;
    text-align: center;
    border-radius: 0;
    margin: 0 1px;
  }
  input::-webkit-inner-spin-button {
    display: none;
  }
}
