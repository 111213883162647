@import '@/styles/colors.module.scss';

.divider {
  margin-top: 0;
}

.radios {
  :not(.ant-radio-button-wrapper-checked) .anticon {
    color: $blue-gray-5;
  }
}
