.wrapper {
  width: 104px;
  height: 104px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  [class~='ant-image'] {
    width: 100%;
    height: 100%;
    [class~='ant-image-img'] {
      height: 100%;
      object-fit: contain;
    }
  }
}
