.card {
  width: 650px;
}

.description {
  margin-bottom: 32px;
}

.fields {
  margin-bottom: 24px;
}
