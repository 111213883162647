@import '@/styles/colors.module.scss';

.section {
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  padding: 24px;
  background: $blue-gray-1;
  .divider {
    margin: 0;
    .title {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
