@import '@/styles/colors.module.scss';

.ant-tag {
  border-radius: 2px;
  padding: 1px 8px;
  &[shape='round'] {
    border-radius: 12px;
  }
}

.ant-tag-red {
  color: $red-6;
  background: $red-1;
  border-color: $red-3;
}


.ant-tag-calendulagold {
  color: $gold-6;
  background: $gold-1;
  border-color: $gold-3;
}


.ant-tag-geekblue {
  color: $geek-blue-6;
  background: $geek-blue-1;
  border-color: $geek-blue-3;
}


.ant-tag-magenta {
  color: $magenta-6;
  background: $magenta-1;
  border-color: $magenta-3;
}

.ant-tag-main {
  color: $main-6;
  background: $main-1;
  border-color: $main-3;
}

.ant-tag-gray {
  color: $gray-9;
  background: $gray-2;
  border-color: $gray-5;
}

.ant-tag-green {
  color: $green-6;
  background: $green-1;
  border-color: $green-3;
}

.ant-tag-purple {
  color: $purple-6;
  background: $purple-1;
  border-color: $purple-3;
}

.ant-tag-polargreen {
  color: $gray-1;
  background:  $green-6;
  border:none
}
